<template>
  <div v-if="hardwareGroups && hardwareGroups.length" t-id="b2b-mobile-phone-product-listing">
    <sorting-and-filtering
      v-if="hardwareBrands.length"
      :primary-options="hardwareBrands"
      :selected-primary-filter="selectedHwBrandFilter"
      :default-sorting-method="hardwareSortingMethod"
      @set-sorting-method="onSetSortingMethod"
      @set-primary-filter="onSetBrandFilter"
    />
    <section
      :t-id="'product-group-' + productGroup.name"
      v-for="(productGroup, index) in sortedAndFilteredHwGroups"
      :key="`${productGroup.name}-${index}`"
      class="hardware-products__category"
    >
      <transition-group
        :css="!hwBrandFilterActivated"
        appear
        tag="div"
        name="fade-zoom"
        class="hardware-products__category-products"
      >
        <product-wrapper
          t-id="b2b-mobile-phone-product-listing__product-wrapper"
          v-for="(product, i) in productGroup.products"
          :key="product.id || product.productCode"
          :class="`
                        hardware-products__listing
                        ${hardwareDisabled ? 'hardware-products__listing-disabled' : ''}
                        `"
          :style="`transition-delay: ${i * 50}ms;`"
          :product="product"
          @click="selectProduct(product, productGroup)"
          @keydown.enter="selectProduct(product, productGroup)"
        />
      </transition-group>
    </section>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { translateSetup, translateMixin } from "./locale";
import ProductWrapper from "./components/product-wrapper.ce.vue";
import SortingAndFiltering from "./components/sorting/sorting-and-filtering.ce.vue";
import { sortBy, SortingOptions } from "./components/sorting/sortingUtils";
import { trackProductList } from "./utils/gaTracking";

export default defineComponent({
  name: "b2b-mobile-phone-product-listing",
  mixins: [translateMixin],
  components: {
    ProductWrapper,
    SortingAndFiltering,
  },
  props: {
    hardwareGroups: {
      type: Array,
      default: () => [],
    },
    hardwareDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    hwBrandFilterActivated: false,
    hardwareSortingMethod: SortingOptions.NEWEST,
    selectedHwBrandFilter: "",
  }),
  mounted() {
    this.trackProductList(this.hardwareGroups);
  },
  async created() {
    await translateSetup();
  },
  computed: {
    hardwareBrands() {
      return [
        ...new Set(this.hardwareGroups?.flatMap((group) => group.products).map((p) => p.brand)),
      ];
    },
    sortedAndFilteredHwGroups() {
      const clone = deepCopy(this.brandFilteredHwGroups);
      return clone?.map((group) => ({
        ...group,
        products: sortBy(group.products, this.hardwareSortingMethod),
      }));
    },
    brandFilteredHwGroups() {
      if (this.selectedHwBrandFilter) {
        return this.hardwareGroups
          .map((group) => ({
            ...group,
            products: group.products.filter((p) => p.brand === this.selectedHwBrandFilter),
          }))
          .filter((group) => group.products.length > 0);
      } else {
        return this.hardwareGroups || [];
      }
    },
  },
  methods: {
    selectProduct(product, productGroup) {
      this.$emit("selected-product", { product: product, productGroup: productGroup });
    },
    onSetSortingMethod(event) {
      this.hardwareSortingMethod = event;
    },
    onSetBrandFilter(event) {
      this.hwBrandFilterActivated = true;
      this.selectedHwBrandFilter = event;
    },
    trackProductList(productGroups) {
      trackProductList(productGroups, this.selectedHwBrandFilter);
    },
  },
  watch: {
    sortedAndFilteredHwGroups: "trackProductList",
  },
});

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
.hardware-products {
  &__category {
    margin: $telia-spacing-64 0;
  }

  &__category-header {
    display: flex;
    align-items: flex-end;
    padding-bottom: $telia-spacing-12;
    border-bottom: 1px solid #d0d0d0;
  }

  &__category-description {
    margin-top: $telia-spacing-24;
  }

  &__category-products {
    margin-top: $telia-spacing-24;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: $telia-spacing-24;
  }

  &__listing {
    &-disabled {
      opacity: 0.17;
      pointer-events: none;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-zoom-enter-active,
.fade-zoom-leave-active {
  transition: opacity 0.1s, transform 0.1s;
}

.fade-zoom-enter,
.fade-zoom-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.hardware-brand-filter-buttons {
  margin: 0 0.8rem 1.8rem 0;
}
</style>

<style lang="scss">
.hardware-brand-filter-buttons .tc-selection-button__content {
  justify-content: center;
  min-width: 10rem;
}
</style>

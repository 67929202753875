<template>
  <div t-id="product-listing" class="mybd-product-listing" tab-index="1">
    <b2x-hardware-product-card
      :t-id="`product-listing__hardware-card-${product.productCode}`"
      :heading="product.name"
      :subheading="product.brand"
      :href="link"
      :price-heading="`Fr. ${hardwareMinPrice}`"
      :image="getImageUrl"
      :key="product.productCode"
      @click.prevent="$emit('click')"
    >
      <telia-color-dot
        slot="colors"
        v-for="color in colorOptions"
        :key="color.name"
        :color="color.hex"
        size="xs"
      />
    </b2x-hardware-product-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import "@telia/b2x-product-card";
import { currentLanguage } from "@telia/b2b-i18n";
import phone from "./TC_Mobile.svg";

export default defineComponent({
  // eslint-disable-next-line vue/component-definition-name-casing
  name: "product-wrapper",
  props: {
    title: String,
    description: String,
    product: Object,
    productVariable: Boolean,
  },
  data: () => ({
    phone,
  }),
  methods: {
    formatCurrency(amount) {
      return new Intl.NumberFormat(currentLanguage(), {
        style: "currency",
        currency: "SEK",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(amount);
    },
  },
  computed: {
    minPrice() {
      return Math.min(...this.product.variants.map((variant) => variant.unitPrice));
    },
    link() {
      return window.location.href;
    },
    hardwareMinPrice() {
      return this.formatCurrency(this.minPrice);
    },
    colorOptions() {
      return [...new Set(this.product.variants.map((variant) => variant.colorHex))].map(
        (option, index) => ({
          name: index,
          hex: option,
        })
      );
    },
    getImageUrl() {
      return this.product.imageUrl ? this.product.imageUrl + "?h=250&fit=fill" : this.phone;
    },
  },
});
</script>

<style lang="scss" scoped></style>

<template>
  <div t-id="mybd-order-products-sorting-selector" class="sorting-selector">
    {{ t("SORT_BY") }}
    <telia-select
      t-id="mybd-order-products-sorting-selector-dropdown"
      :options="JSON.stringify(options)"
      @change="onChange($event.target.value)"
    />
  </div>
</template>

<script>
import { SortingOptions } from "./sortingUtils";
import { translateMixin } from "../../locale";
import { defineComponent } from "vue";

export default defineComponent({
  name: "sorting-selector",
  mixins: [translateMixin],
  props: {
    defaultMethod: { type: String, required: true },
  },
  data() {
    return {
      sortingMethod: this.defaultMethod,
      options: Object.keys(SortingOptions).map((option) => ({
        label: this.t(`SORTING_OPTION_${option}`),
        value: option,
        disabled: false,
        selected: this.sortingMethod === option,
      })),
    };
  },
  methods: {
    onChange(value) {
      this.sortingMethod = value;
      this.$emit("set-sorting-method", this.sortingMethod);
    },
  },
});
</script>

<style lang="scss" scoped>
.sorting-selector {
  display: flex;
  align-items: center;
  text-align: right;
  margin-top: 8px;
  margin-bottom: auto;
}
.sorting-selector * {
  margin-left: 2px;
  text-align: initial;
}
</style>

<template>
  <div t-id="primary-filter" class="sorting-and-filtering__primary-filter">
    <telia-chip-choice
      t-id="primary-filter-all"
      value=""
      @change="onChange"
      :checked="'' === selectedFilter"
    >
      {{ t("HW_PRODUCTS_FILTER_ALL") }}
    </telia-chip-choice>
    <telia-chip-choice
      v-for="option of options"
      :t-id="'primary-filter-' + option"
      :key="option"
      :value="option"
      :checked="option === selectedFilter"
      @change="onChange"
    >
      {{ option }}
    </telia-chip-choice>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { translateMixin } from "../../locale";

export default defineComponent({
  name: "PrimaryFilter",
  mixins: [translateMixin],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    selectedFilter: "",
  }),
  methods: {
    onChange(selected) {
      if (this.selectedFilter === selected.target.value) {
        this.selectedFilter = "";
      } else {
        this.selectedFilter = selected.target.value;
      }
      this.$emit("selected-option", this.selectedFilter);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
.sorting-and-filtering {
  &__primary-filter {
    display: flex;
    flex-flow: row wrap;
    gap: $telia-spacing-8;
  }
}
</style>

const SortingOptions = {
  NEWEST: "NEWEST",
  HIGHEST_PRICE: "HIGHEST_PRICE",
  LOWEST_PRICE: "LOWEST_PRICE",
};

function sortBy(items, key) {
  switch (key) {
    case SortingOptions.HIGHEST_PRICE:
      return items.sort((a, b) => (getMinimumPrice(a) > getMinimumPrice(b) ? -1 : 1));
    case SortingOptions.LOWEST_PRICE:
      return items.sort((a, b) => (getMinimumPrice(a) < getMinimumPrice(b) ? -1 : 1));
    case SortingOptions.NEWEST:
      if (items.some((i) => i.mostRecentVariantSellingStartDate || i.sellingStartDate)) {
        return items.sort((a, b) => {
          const aSellingStartDate = a.mostRecentVariantSellingStartDate || a.sellingStartDate;
          const bSellingStartDate = b.mostRecentVariantSellingStartDate || b.sellingStartDate;
          if (aSellingStartDate === bSellingStartDate) {
            return 0;
          } else if (aSellingStartDate === null) {
            return 1;
          } else if (bSellingStartDate === null) {
            return -1;
          } else {
            return bSellingStartDate > aSellingStartDate ? 1 : -1;
          }
        });
      } else {
        return items;
      }
  }
  return items;
}

function getMinimumPrice(productGroup) {
  return Math.min(...(productGroup.variants?.map((v) => v.unitPrice) || [productGroup.unitPrice]));
}

export { SortingOptions, sortBy };
